<template>
  <b-container class="c-page">
    <div class="c-page__title" >
      <div>
        <h1>{{$t('users.title')}}</h1>
      </div>
      <div class="c-page__controls">
        <b-icon class="clickable" icon="plus-square-fill" variant="primary"
                @click="addNewItem()"></b-icon>
      </div>
    </div>
    <table class="table c-page__table">
      <thead>
      <tr>
        <th>{{$t('users.name')}}</th>
        <th>{{$t('users.email')}}</th>
        <th>{{$t('users.permissions')}}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <template v-for="item in posts.records" >
        <tr :key="item.id" >
          <td>
            {{item.name}}
          </td>
          <td>
            {{item.email}}
          </td>
          <td>
            {{$t('users.role.'+item.permission)}}
          </td>
          <td class="text-right c-page__table-edit">
            <router-link :to="{name: 'usersEdit', params: {id: item.id}}">
              <b-icon class="clickable" icon="pencil-square" variant="primary" ></b-icon>
            </router-link>

          </td>
        </tr>
      </template>
      </tbody>
    </table>
    <router-view @loadData="loadData()"></router-view>
  </b-container>

</template>

<script>

export default {
  name: 'users',
  data() {
    return {
      posts: []
    }
  },
  methods: {
    async loadData() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/users/users.php',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          method: 'GET'
        });

        this.posts = response.data;

      } catch (error) {
        console.log("error", error);
        this.$eventHub.$emit('openMessageController', 'error', 'No data');
      }

    },
    async addNewItem() {
      try {
        const response = await this.$http({
          url: process.env.VUE_APP_SERVER + 'api/users/add-new-item.php',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          method: 'POST'
        });

        await this.$router.push( {name: 'usersEdit', params: {id: response.data.id}} );


      } catch (error) {
        this.loadData();
        this.$eventHub.$emit('openMessageController', 'error', error.response.data.message);
      }
    }
  },
  computed: {
  },
  mounted () {
    this.loadData();

  }
}
</script>

<style scoped lang="scss">

</style>
